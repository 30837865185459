import { type UserFeatures, type UserJourney } from '@vdi/auth-service'
import { authorizationHelper, authService } from '../../initializeServices'
import { navigateToUrl } from 'single-spa'
import { monitoringClient } from '../monitoring'

interface Permission {
  path: string
  featureName: UserFeatures[]
  journeyName?: UserJourney
}

const permissions: Permission[] = [
  {
    path: '/admin/usuarios',
    featureName: ['view-people-management']
  },
  {
    path: '/admin-users/usuarios/criar',
    featureName: ['view-people-management']
  },
  {
    path: '/admin/equipes/cadastrar',
    featureName: ['view-people-management']
  },
  {
    path: '/admin/equipes',
    featureName: ['view-people-management']
  },
  {
    path: '/admin',
    featureName: ['view-people-management']
  },
  {
    path: '/dashboards/visao-geral',
    featureName: ['gr-dashboards-overview', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/dashboards/atendimento',
    featureName: ['gr-dashboards-attendance', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/dashboards/relacionamento',
    featureName: ['gr-dashboards-attendance', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/dashboards/perfil-de-base',
    featureName: ['gr-dashboards-base-profile', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/dashboards/atividade',
    featureName: ['gr-dashboards-activity', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/dashboards/receita',
    featureName: ['gr-dashboards-revenue', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/dashboards/memoria-de-calculo',
    featureName: ['gr-dashboards-overview', 'gr-dashboards-attendance', 'gr-dashboards-full'],
    journeyName: 'ca'
  },
  {
    path: '/ativacao/atendimento',
    featureName: ['read-all-res'],
    journeyName: 'ca'
  },
  {
    path: '/inicios/atendimento',
    featureName: ['view-attendance'],
    journeyName: 'ci'
  },
  {
    path: '/inicios/centrais-de-servico',
    featureName: ['create-checklist'],
    journeyName: 'ci'
  },
  {
    path: '/inicios/logs',
    featureName: ['view-logs'],
    journeyName: 'ci'
  },
  {
    path: '/inicios/mailing/atendente/base-de-irs',
    featureName: ['view-mailing'],
    journeyName: 'ci'
  },
  {
    path: '/inicios/mailing/control/distribuicao-irs',
    featureName: ['can-segmentation-base'],
    journeyName: 'ci'
  },
  {
    path: '/inicios/mailing/control/selecionar-assistentes',
    featureName: ['can-segmentation-base'],
    journeyName: 'ci'
  },
  {
    path: '/inicios/admin',
    featureName: ['view-admin'],
    journeyName: 'ci'
  },
  {
    path: '/ativacao/segmentacao',
    featureName: ['can-view-segmentation'],
    journeyName: 'ca'
  },
  {
    path: '/chat',
    featureName: ['view-chat']
  }
]

export function permissionRoutes() {
  window.addEventListener('single-spa:before-routing-event', (evt: CustomEvent) => {
    const path = location.pathname

    if (path === '/acesso-restrito') return

    for (const permission of permissions) {
      const { featureName, journeyName } = permission

      // A.T.A - advanced technical artifice
      const canAccessFeature = featureName.some((feature) =>
        authorizationHelper.canAccessFeature({ featureName: feature, journeyName })
      )

      if (path === permission.path && !canAccessFeature) {
        const featureNameToString = featureName.toString()
        monitoringClient.error('User does not have permission to access this route', {
          id: authService.userData.id,
          path,
          featureNameToString,
          code: 'VDI-403'
        })

        navigateToUrl('/acesso-restrito')
      }
    }
  })
}
