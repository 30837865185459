import { constructRoutes } from 'single-spa-layout'
import { renderCaRoutes } from './renderCaRoutes'
import { renderCiRoutes } from './renderCiRoutes'
import { renderApp, renderAppWithRoute } from '../renderApp'
import { type HTMLLayoutData } from 'single-spa-layout/dist/types/isomorphic/constructRoutes'

import lockIcon from '../icons/lockIcon.svg'
import exclamationMarkTriangleFillIcon from '../icons/exclamationMarkTriangleFillIcon.svg'
import { authorizationHelper } from '../../initializeServices'

export function factoryUserRoutes({ layoutData }: { layoutData: HTMLLayoutData | undefined }) {
  return constructRoutes(
    `<single-spa-router>
      <div class="root-app-wrapper">
        ${renderApp({ appName: '@vdi/navbar', canRender: true, props: 'user' })}

        <main>
          ${renderCaRoutes()}
          ${renderCiRoutes()}

          ${renderAppWithRoute({
            appName: '@vdi/admin',
            path: '/admin',
            canRender: authorizationHelper.canAccessFeature({
              featureName: 'view-people-management',
              journeyName: 'ca'
            })
          })}

          <route path="chat">
            <application name="@vdi/chatbot-ts" props="user"></application>
          </route>

          <route path="/acesso-restrito">
            <div class="error-page">
              <div class="icon">
                <img src=${lockIcon} alt="icone que representa acesso restrito" />
              </div>
              <span class="title">Acesso restrito</span>
              <span class="subtitle">Parece que você não tem acesso a essa página.</span>
            </div>
          </route>

          <route default>
            <div class="page-not-found error-page">
              <div class="icon">
                <img src=${exclamationMarkTriangleFillIcon} alt="icone que representa atenção" />
              </div>
              <span class="title">Página não encontrada</span>
              <span class="subtitle">Parece que você acessou uma página que não existe.</span>
            </div>
          </route>

        </main>
      </div>
    </single-spa-router>
  `,
    layoutData
  )
}
